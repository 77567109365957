.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.App-header {
  text-align: center;
  animation: blinking-text 2s infinite;
  text-shadow:
    4px 4px 0 #ff69b4,
    8px 8px 0 #3498db;

  h1 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 4em;
  }
}

.App-food-label {
  height: 3em;
  animation: zoom-in-zoom-out 1s ease infinite;
  font-family: impact;
  background: linear-gradient(45deg, #3498db, #ff69b4);
  font-size: 2em;
  text-align: center;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.App-spinner {
  pointer-events: none;
  max-width: 80%;
  max-height: 80%;

  @media (min-width: 768px) {
    max-width: 100%; /* Adjust the maximum width for larger screens */
    max-height: 50vh; /* Adjust the maximum height for larger screens */
  }

  &.spin {
    animation: dizzy-dish-spinner 3s ease-out forwards; /* Adjust the duration and timing function */
  }
}

.App-button {
  font-size: 20px;
  font-weight: 600;
  box-sizing: border-box;
  background: linear-gradient(45deg, #ff69b4, #3498db);
  cursor: pointer;
  text-transform: uppercase;
  color: #ffffff;
  padding: 15px 32px;
  margin-bottom: 24px;
  margin-top: auto;

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
    color: #888888;
  }
}

@keyframes blinking-text {
  0% {
    color: #10c018;
  }
  25% {
    color: #1056c0;
  }
  50% {
    color: #ef0a1a;
  }
  75% {
    color: #254878;
  }
  100% {
    color: #04a1d5;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dizzy-dish-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1800deg); /* Adjust the degrees for a single rotation */
  }
}
